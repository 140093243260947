import logo from "./logo.svg";
import "./App.css";
import VideoPlayer from "./components/VideoPlayer";
import { Center, Container, MantineProvider, Text, Title } from "@mantine/core";

import videoData from "./data.json";
import { useEffect, useState } from "react";

function App() {
  const [isTime, setIsTime] = useState(true);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const currentDate = new Date();
  //     const currentHour = currentDate.getHours();
  //
  //     if (currentHour >= 15 && currentHour < 22) {
  //       setIsTime(true);
  //     } else {
  //       setIsTime(false);
  //     }
  //   }, 1000); // Update every second
  //
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Container className="App">
        <Container id="header">
          <Title color="orange">SUBDIMENSIONAL PARLEY</Title>
        </Container>
        {!isTime ? (
          <Center id="no-time">
            <Text color="white">Not time yet</Text>
          </Center>
        ) : (
          <>
            <VideoPlayer videos={videoData} isTime={isTime} />
            <Container id="footer"></Container>
          </>
      )}
      </Container>

    </MantineProvider>
  );
}

export default App;
