import React, { Component, useEffect, useState } from "react";
import { Button, Container } from "@mantine/core";

export default function VideoPlayer(videos, isTime) {
  const [currentVideo, setCurrentVideo] = useState(null);
  const [play, setPlay] = useState(false);

  let videoPlayer;

  useEffect(() => {
    if (videos.videos.length > 0) {
      // get random number from 0 to length of videos array
      const randomVideoIndex = Math.floor(Math.random() * videos.videos.length);
      //set current video to random video
      setCurrentVideo(videos.videos[randomVideoIndex]);
    }
  }, [videos]);

  useEffect(() => {
    if (currentVideo != null) {
      videoPlayer = document.getElementById("video-player");
      videoPlayer.src = '/videos/' + currentVideo.url;
      videoPlayer.play();
      videoPlayer.addEventListener("ended", playNextVideo);
    }
  }, [currentVideo]);

  function StartPlay() {
    if (play) return;
    if (isTime) {
      setPlay(true);
      videoPlayer.play();
    }
  }

  function playNextVideo() {
    const randomVideoIndex = Math.floor(Math.random() * videos.videos.length);
    //set current video to random video
    setCurrentVideo(videos.videos[randomVideoIndex]);
  }

  return (
    <Container id="content" onClick={StartPlay} class={isTime ? "" : "hidden"}>
      {currentVideo && (
        <>
          {/*<h1>{currentVideo.title}</h1>*/}
          {/*<p>{currentVideo.description}</p>*/}
          <video id="video-player" class={!play ? "opacity" : ""} />
        </>
      )}
      {!play && (
        <div id="start_button">
          <Button color="orange" size="xl" onClick={StartPlay}>
            Play
          </Button>
        </div>
      )}
    </Container>
  );
}
